import echarts from 'echarts'
import theme from './echartTheme'
import dayjs from 'dayjs'

const bgColor = '#02208C'
const color = ['#0283FF', '#16CEB9', '#FFB400', '#FF3C7E']

export const createBarEchart = (id, data, nameProp, myInstance) => {
  const legend = data.map(d => d.title)
  const option = {
    color,
    backgroundColor: bgColor,
    title: {
      show: false
    },
    tooltip: {
      trigger: 'axis',
      axisPointer: {
        type: 'shadow'
      },
      formatter: function (params) {
        console.log(params)
        var relVal = params[0].axisValueLabel
        for (var i = 0, l = params.length; i < l; i++) {
          relVal += '<br/>' + params[i].seriesName + ' : ' + params[i].value
        }
        return relVal
      }
    },
    calculable: true,
    legend: {
      x: 'center',
      y: 20,
      data: legend,
      textStyle: {
        color: '#fff'
      },
      icon: 'circle'
    },
    grid: {
      left: '3%',
      right: '4%',
      bottom: 20,
      containLabel: true
    },
    xAxis: [{
      type: 'category',
      axisLabel: {
        textStyle: {
          color: '#fff'
        }
      },
      data: data[0].data.map((v, i) => i + 1)
    }],
    yAxis: [{
      type: 'value',
      axisLabel: {
        textStyle: {
          color: '#fff'
        }
      }
    }],
    series: data.map(d => ({
      name: d.title,
      type: 'bar',
      barWidth: 50,
      data: d.data.map(s => ({ name: s[nameProp], value: s.quantityNumber })),
      animationDelay: function (idx) {
        return idx * 10
      }
    })),
    animationDuration: 2000,
    animationEasing: 'elasticOut',
    animationDelayUpdate: function (idx) {
      return idx * 100 + 4000
    }
  }
  let instance = myInstance || echarts.init(document.getElementById(id), theme)
  // console.log(JSON.parse(JSON.stringify(option)))
  instance.setOption(option)
  return instance
}

export const createLineEchart = (id, data, xAxisData, myInstance) => {
  const legend = data.map(d => d.name)
  const option = {
    color,
    backgroundColor: bgColor,
    title: {
      show: false
    },
    tooltip: {
      trigger: 'axis',
      axisPointer: {
        type: 'shadow'
      },
      formatter: function (params) {
        var relVal = params[0].axisValueLabel
        for (var i = 0, l = params.length; i < l; i++) {
          relVal += '<br/>' + params[i].seriesName + ' : ' + params[i].value
        }
        return relVal
      }
    },
    calculable: true,
    legend: {
      x: 'center',
      y: 10,
      data: legend,
      textStyle: {
        color: '#fff'
      },
      icon: 'circle'
    },
    grid: {
      left: '3%',
      right: '4%',
      bottom: 20,
      top: 40,
      containLabel: true
    },
    xAxis: [{
      type: 'category',
      axisLabel: {
        textStyle: {
          color: '#fff'
        }
      },
      data: xAxisData
    }],
    yAxis: [{
      type: 'value',
      // name: '单位:方',
      nameTextStyle: {
        color: '#fff'
      },
      axisLabel: {
        textStyle: {
          color: '#fff'
        }
      }
    }],
    series: data.map(d => ({
      name: d.name,
      type: 'line',
      symbol: 'emptyCircle',
      showSymbol: true,
      showAllSymbol: true,
      data: d.data,
      areaStyle: {},
      animationDelay: function (idx) {
        return idx * 10
      }
    })),
    animationDuration: 2000,
    animationEasing: 'elasticOut',
    animationDelayUpdate: function (idx) {
      return idx * 100 + 4000
    }
  }
  let instance = myInstance || echarts.init(document.getElementById(id), theme)
  instance.setOption(option)
  return instance
}

export const createPieChart = (id, data) => {
  var option = {
    color,
    backgroundColor: 'transparent',
    title: {
      show: false
    },
    legend: {
      orient: 'vertical',
      x: 'right',
      y: 'center',
      data: data.map(d => d.name),
      textStyle: {
        color: '#fff'
      },
      icon: 'circle'
    },
    tooltip: {
      trigger: 'item',
      formatter: '{a} <br/>{b} : {c} ({d}%)',
      confine: true
    },
    series: [{
      name: '',
      type: 'pie',
      radius: ['50%', '70%'],
      center: ['35%', '50%'],
      data: data,
      label: {
        show: false,
        position: 'inside',
        color: '#fff',
        textBorderColor: 'transparent'
      }
    }]
    // animationDurationUpdate: 3000
  }
  const instance = echarts.init(document.getElementById(id), theme)
  instance.setOption(option)
  return instance
}

export const createCustomChart = (id, categories, data, startTime) => {
  function renderItem (params, api) {
    var categoryIndex = api.value(0)
    var start = api.coord([api.value(1), categoryIndex])
    var end = api.coord([api.value(2), categoryIndex])
    var height = api.size([0, 1])[1] * 0.6

    var rectShape = echarts.graphic.clipRectByRect({
      x: start[0],
      y: start[1] - height / 2,
      width: end[0] - start[0],
      height: height
    }, {
      x: params.coordSys.x,
      y: params.coordSys.y,
      width: params.coordSys.width,
      height: params.coordSys.height
    })

    return rectShape && {
      type: 'rect',
      shape: rectShape,
      style: api.style()
    }
  }

  const option = {
    grid: {
      top: 10,
      left: 120,
      bottom: 30,
      right: 50
    },
    tooltip: {
      formatter: function (params) {
        const st = dayjs(params.value[1]).format('MM/DD HH:mm:ss')
        const et = dayjs(params.value[2]).format('MM/DD HH:mm:ss')
        return params.marker + params.name + ': ' + st + '~' + et
      }
    },
    xAxis: {
      min: startTime,
      axisLabel: {
        formatter: function (val) {
          return dayjs(val).format('MM/DD HH:mm:ss')
        }
      }
    },
    yAxis: {
      data: categories
    },
    series: [{
      type: 'custom',
      renderItem: renderItem,
      itemStyle: {
        opacity: 0.8
      },
      encode: {
        x: [1, 2],
        y: 0
      },
      data: data
    }]
  }

  const instance = echarts.init(document.getElementById(id), theme)
  instance.setOption(option)
  return instance
}

export let pieOption = {
  color,
  backgroundColor: 'transparent',
  title: {
    show: false
  },
  legend: {
    orient: 'vertical',
    x: 'right',
    y: 'center',
    textStyle: {
      color: '#fff'
    },
    icon: 'circle'
  },
  tooltip: {
    trigger: 'item',
    formatter: '{a} <br/>{b} : {c} ({d}%)',
    confine: true
  },
  series: [{
    name: '',
    type: 'pie',
    radius: ['40%', '54%'],
    center: ['35%', '50%'],
    label: {
      show: false,
      position: 'inside',
      color: '#fff',
      textBorderColor: 'transparent'
    }
  }]
  // animationDurationUpdate: 3000
}

export let gridBarOption = {
  color: ['rgba(2, 131, 255, .5)', 'rgba(22, 206, 185, .5)'],
  legend: {
    selectedMode: false,
    textStyle: {
      color: '#fff'
    }
  },
  grid: [
    { left: 50, right: '50%', top: 40, bottom: 30 },
    { left: '50%', right: 50, top: 40, bottom: 30 }
  ],
  tooltip: {},
  xAxis: [
    {
      type: 'category',
      // data: xAxisData,
      gridIndex: 0,
      splitLine: {
        show: true,
        lineStyle: {
          color: 'rgba(255,255,255, .3)'
        }
      },
      axisLine: {
        lineStyle: {
          color: '#fff'
        }
      },
      axisLabel: {
        lineStyle: {
          color: '#fff'
        }
      },
      axisTick: {
        lineStyle: {
          color: '#fff'
        }
      }
    },
    {
      type: 'category',
      // data: xAxisData2,
      gridIndex: 1,
      splitLine: {
        show: true,
        lineStyle: {
          color: 'rgba(255,255,255, .3)'
        }
      },
      axisLine: {
        lineStyle: {
          color: '#fff'
        }
      },
      axisLabel: {
        lineStyle: {
          color: '#fff'
        }
      },
      axisTick: {
        lineStyle: {
          color: '#fff'
        }
      }
    }
  ],
  yAxis: [
    {
      gridIndex: 0,
      type: 'value',
      min: 0,
      max: 100,
      // name: '单位:方',
      // nameLocation: 'middle',
      splitLine: {
        show: true,
        lineStyle: {
          color: 'rgba(255,255,255, .3)'
        }
      },
      axisLine: {
        lineStyle: {
          color: '#fff'
        }
      },
      axisLabel: {
        lineStyle: {
          color: '#fff'
        }
      },
      axisTick: {
        lineStyle: {
          color: '#fff'
        }
      }
    },
    {
      gridIndex: 1,
      type: 'value',
      min: 0,
      max: 100,
      splitLine: {
        show: true,
        lineStyle: {
          color: 'rgba(255,255,255, .3)'
        }
      },
      axisLine: { show: false },
      axisLabel: { show: false },
      axisTick: { show: false }
    }
  ],
  series: [
    {
      name: '56米',
      type: 'bar',
      barWidth: 15,
      yAxisIndex: 0,
      xAxisIndex: 0,
      label: {
        show: true,
        position: 'top',
        color: '#fff'
      },
      itemStyle: {
        barBorderRadius: [10, 10, 0, 0]
      }
      // data: data1
    },
    {
      name: '62米',
      type: 'bar',
      barWidth: 15,
      yAxisIndex: 1,
      xAxisIndex: 1,
      label: {
        show: true,
        position: 'top',
        color: '#fff'
      },
      itemStyle: {
        barBorderRadius: [10, 10, 0, 0]
      }
      // data: data2
    }
  ]
}
