<template>
  <div class="production-history">
    <div class="video-wrapper">
      <div v-if="clicked"
           class="history-player-area">
        <div class="player-wrapper">
          <flv-player history
                      ref="player"
                      :switch-url="switchUrl"
                      :video-url="videoUrl"></flv-player>
        </div>
        <div class="video-infos">
          <div class="title">播放文件详情</div>
          <div class="desc">自编号：{{video.number}}</div>
          <div class="desc">通道号：{{video.tdh}}</div>
          <div class="desc">开始时间：{{video.st}}</div>
          <div class="desc">结束时间：{{video.et}}</div>
          <div class="desc">文件大小：{{video.size}}</div>
        </div>
      </div>
      <div class="no-data"
           v-else>
        <img src="/img/no-data.png">
      </div>
    </div>
    <div class="bottom-graph">
      <div class="operations">
        <el-form :model="form"
                 inline
                 ref="form">
          <el-form-item label="车辆"
                        :rules="{required: true,message:'请选择车辆信息'}"
                        prop="carid">
            <el-select filterable
                       clearable
                       v-model="form.carid">
              <el-option v-for="n in carList"
                         :key="n.id"
                         :label="n.number"
                         :value="n.carDeviceId"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="开始时间"
                        :rules="{required: true,message:'请选择开始时间'}"
                        prop="beginTime">
            <el-date-picker value-format="yyyyMMddHHmmss"
                            type="datetime"
                            v-model="form.beginTime"></el-date-picker>
          </el-form-item>
          <el-form-item label="结束时间"
                        :rules="{required: true,message:'请选择结束时间'}"
                        prop="endTime">
            <el-date-picker value-format="yyyyMMddHHmmss"
                            type="datetime"
                            v-model="form.endTime"></el-date-picker>
          </el-form-item>
          <el-button type="primary"
                     :loading="loading"
                     @click="getVideoInfo">查询</el-button>
        </el-form>
      </div>
      <div class="graph-area-wrapper"
           v-loading="loading">
        <div class="no-data"
             v-if="!videoSource || !videoSource.length">
          <img src="/img/no-data.png">
        </div>
        <div id="graph"></div>
      </div>
    </div>
  </div>
</template>

<script>
import dayjs from 'dayjs'
import axios from 'axios'
import { createCustomChart } from '../../../utils/echartUtils.js'
import { videoType, restfulUrl, webSocketUrl, historyFileUrl } from '@/config'
import FlvPlayer from '@/components/flv-player.vue'

export default {
  name: 'production-history',
  components: { FlvPlayer },

  data() {
    return {
      loading: false,
      form: {
        func: 1004,
        carid: ' ',
        beginTime: '',
        endTime: '',
      },
      carList: [],
      switchUrl: '',
      videoUrl: '',
      clicked: false,
      videoSource: [],
      video: {},
    }
  },
  methods: {
    getVideoInfo() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          const start = dayjs(this.form.beginTime)
          const end = dayjs(this.form.endTime)
          if (start > end) {
            return this.$opts.alert('开始时间不能晚于结束时间!')
          }
          // if (start.month() !== end.month()) {
          //   return this.$opts.alert('开始时间与结束时间不能跨月!')
          // }
          if (end.diff(start, 'day') > 3) {
            return this.$opts.alert('开始时间与结束时间不能超过3天!')
          }
          this.destroyChart()
          this.clicked = false
          let reqCount = 0
          this.loading = true
          this.videoSource = []
          // 请求单个通道
          const getChannel = () => {
            axios
              .get(restfulUrl + '/api.json', {
                params: {
                  tdh: reqCount + 1,
                  ...this.form,
                },
              })
              .then((response) => {
                reqCount++
                if ([1, -85].includes(response.data.code)) {
                  // 接口1正常返回，延时请求接口2
                  setTimeout(() => {
                    axios
                      .get(
                        historyFileUrl +
                          `/?json={%22tid%22:%22${this.form.carid}%22,%22cmd%22:%221009%22,%22ticket%22:%2243b2f84b-6e2d-4c4a-87d6-ab67e8c883ae%22}`
                      )
                      .then((res) => {
                        if (res.data.code === 1) {
                          // 接口2正常返回
                          if (res.data.media_list) {
                            this.videoSource = this.videoSource.concat(
                              res.data.media_list.filter((m) => m.type === '0')
                            )
                          }
                        } else {
                          // 接口2异常
                          this.$opts.alert(res.data.message)
                        }
                        if (reqCount === 8) {
                          // 所有通道正常返回
                          this.loading = false
                          if (this.videoSource.length) {
                            this.createGraph(this.videoSource)
                          }
                        } else {
                          // 延时请求下一个通道
                          setTimeout(() => {
                            getChannel()
                          }, 200)
                        }
                      })
                  }, 500)
                } else {
                  // 不在线
                  if (reqCount === 8) {
                    // 所有通道请求完毕，且包含不在线通道
                    this.loading = false
                  } else {
                    // 延时请求下一个通道
                    setTimeout(() => {
                      getChannel()
                    }, 200)
                  }
                }
              })
          }
          getChannel()
        }
      })
    },
    createGraph(source) {
      const categories = []
      const data = []
      let startTime = dayjs().valueOf()
      for (const t of source) {
        const st = dayjs(t.stime).valueOf()
        if (st < startTime) {
          startTime = st
        }
      }
      for (let i = 0; i < videoType.length; i++) {
        categories.push(videoType[i])
        const targets = source.filter((m) => m.tdh === (i + 1).toString())
        for (const t of targets) {
          const st = dayjs(t.stime).valueOf()
          const et = dayjs(t.etime).valueOf()
          const duration = et - st
          data.push({
            value: [i, st, et, duration, t],
          })
        }
      }
      this.chart = createCustomChart(
        'graph',
        categories,
        data,
        startTime.valueOf()
      )
      this.chart.on('click', (e) => {
        const d = e.data.value
        const st = dayjs(d[1]).format('YYYYMMDDHHmmss')
        const et = dayjs(d[2]).format('YYYYMMDDHHmmss')
        const tdh = d[0] + 1
        this.video = {
          number: this.carList.find((c) => c.carDeviceId === this.form.carid)
            .number,
          tdh: videoType[d[4].tdh - 1],
          st: dayjs(d[1]).format('YYYY-MM-DD HH:mm:ss'),
          et: dayjs(d[2]).format('YYYY-MM-DD HH:mm:ss'),
          size: Math.round(d[4].length / 1024 / 1024) + 'M',
        }
        const param = {
          tdh,
          type: 1,
          tid: this.form.carid,
        }
        this.switchUrl =
          restfulUrl +
          `/api.json?func=1005&carid=${this.form.carid}&tdh=${tdh}&beginTime=${st}&endTime=${et}`
        this.videoUrl =
          webSocketUrl +
          `/websocket?token=&params=${encodeURIComponent(
            JSON.stringify(param)
          )}`
        this.clicked = true
      })
    },

    destroyChart() {
      if (this.chart) {
        window.removeEventListener('resize', this.chart._dom.$resizer)
        this.chart.clear()
        this.chart.dispose()
      }
    },
  },

  async created() {
    let data = await this.$http({
      url: this.$http.adornUrl('/car/list'),
      method: 'post',
      data: {},
    })
    this.carList = data.datas
  },

  beforeDestroy() {
    this.destroyChart()
  },
}
</script>

<style lang="scss">
.production-history {
  padding: 30px;
  .video-wrapper {
    height: calc(55vh - 20px);
    margin-bottom: 20px;
    min-height: 400px;
    border: 1px solid #ccc;
    border-radius: 4px;
    position: relative;
    video {
      width: 100%;
      height: 100%;
    }
    .history-player-area {
      padding: 28px;
      display: flex;
      justify-content: center;
      align-items: center;
      height: 100%;
      background-image: url('/img/history-bg.png');
      background-size: 100% 100%;
      .player-wrapper {
        width: 50%;
        height: 100%;
      }
      .video-infos {
        margin-left: 60px;
        .title {
          color: #e60012;
          font-size: 32px;
          margin-bottom: 30px;
        }
        .desc {
          color: #1a1a1a;
          font-size: 26px;
          margin-bottom: 20px;
        }
      }
    }
  }
  .bottom-graph {
    height: 45vh;
    min-height: 350px;
    background: #fff;
    box-shadow: 0px 0px 10px 0px rgba(103, 103, 103, 0.1);
    border-radius: 4px;
    padding-bottom: 10px;
    .operations {
      border-bottom: 1px solid #eeeeee;
      padding: 15px 20px;
      .el-form-item {
        margin-right: 30px;
        margin-bottom: 10px;
      }
    }
    .graph-area-wrapper {
      margin: 20px;
      border: 1px solid #ccc;
      border-radius: 2px 4px 4px 4px;
      height: calc(100% - 110px);
      position: relative;
      #graph {
        height: 100%;
      }
    }
  }
  .no-data {
    position: absolute;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    top: 0;
    left: 0;
    z-index: 999;
  }
}
</style>
